export const ModifiedSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#F3F1F1" : "white",
    color: state.isSelected ? "#000" : "#000",
    "&:hover": {
      background: "#F3F1F1",
      color: "000000",
      transition: "all 0.8s",
    },
  }),
  control: (styles) => ({
    ...styles,
    border: "none",
    backgroundColor: "#F3F1F1",
    color: "#000000",
    borderRadius: "0px",
    height: "50px",
    width: "200px",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      borderColor: "#a0a293",
    },
    fontWeight: "510",
    fontSize: "20px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    borderColor: "2px solid #dadada",
    color: "#000",
    width: "200px",
  }),
  menuList: (base) => ({
    ...base,
    cursor: "pointer",
    color: "#000",
  }),
  indicatorContainer: (base) => ({
    ...base,
    color: "#000000",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "20px",
    color: "#000000",
    fontWeight: "400",
    textAlign: "left",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#000";
    const fontSize = "20px";
    const textAlign = "left";
    const fontWeight = "400";
    return {
      ...provided,
      transition,
      color,
      opacity,
      fontSize,
      textAlign,
      fontWeight,
    };
  },
};
