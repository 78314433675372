import React, { useEffect, useState } from "react";
import "./style.scss";
import * as _ from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { MdImageNotSupported } from "react-icons/md";
import Logo from "../../../assets/images/asthi-logo.svg";
import { SlClose } from "react-icons/sl";
import DataNotFound from "../../../components/shared/DataNotFound/DataNotFound";
import { ModifiedSelectStyle } from "../../../utils/constants/selectStyle";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import i18n from "../../../i18n";
import { useGetLastVisitQuery } from "../../../api/lastVisit";

const LastVisitInfo = () => {
  const location = useLocation();

  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [allImageStor, setAllImageStor] = useState([]);
  const [allInstruction, setAllInstruction] = useState([]);
  const [getLastVisitResData, setLastVisitResData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "english"
  );

  function base64ToString(base64) {
    // Decode the Base64 string into a decoded string
    const decodedString = atob(base64);

    // Return the decoded string
    return decodeURIComponent?.(escape(decodedString));
  }

  const [searchParams] = useSearchParams();
  const idNumber = searchParams?.get("idNumber"); // "testCode"
  const mobileNumber = searchParams?.get("mobileNumber"); // "testCode"

  const { data: getLastVisitRes } = useGetLastVisitQuery(
    {
      idNumber: idNumber ? base64ToString(idNumber) : "",
      mobileNumber: mobileNumber ? base64ToString(mobileNumber) : "",
    },
    { skip: !idNumber && !idNumber, refetchOnFocus: true }
  );

  useEffect(() => {
    if (!getLastVisitRes?.status === 200) return;
    setLastVisitResData(getLastVisitRes?.data);
  }, [getLastVisitRes]);

  const LastVisitInfo = location?.state?.data || getLastVisitResData;
  const Instruction =
    LastVisitInfo?.instruction || getLastVisitResData?.instruction;
  const exercise = LastVisitInfo?.exercise || getLastVisitResData?.exercise;

  const form = useForm({});

  const {
    formState: { errors },
    control,
  } = form;

  useEffect(() => {
    if (Instruction) {
      const allInstruction = Instruction.reduce((InstructItem, item) => {
        if (item?.instructions) {
          InstructItem.push(...item?.instructions);
        }
        return InstructItem;
      }, []);
      setAllInstruction(allInstruction);
    }
    if (exercise) {
      const allImages = exercise.reduce((image, item) => {
        if (item?.images) {
          image.push(...item?.images);
        }
        return image;
      }, []);
      setAllImageStor(allImages);
    }
  }, [exercise, Instruction]);

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  const selectLanguage = [
    { value: "english", label: "English" },
    { value: "gujarati", label: "Gujarati" },
    { value: "hindi", label: "Hindi" },
  ];

  const handleSelectLanguage = (selectedOption) => {
    const selectedLanguage = selectedOption?.value;
    setSelectedLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const galleryItems = allImageStor.map((image) => ({
    original: image.image,
    thumbnail: image.image,
  }));

  const getInstructionText = (instruction) => {
    const languageInstruction = instruction.instructionLanguage.find(
      (lang) =>
        lang.languageId.name.toLowerCase() === selectedLanguage.toLowerCase()
    );
    return languageInstruction ? languageInstruction.text : instruction.text;
  };

  return (
    <>
      <div className="container-fluid p-0" id="lastVisitInfo">
        <div className="logo-img text-center w-100">
          <img src={Logo} alt={"Logo"} />
        </div>
        {!_.isEmpty(LastVisitInfo) ? (
          <div className="row p-4 m-0 lastVisitInfo">
            <div className="col-12">
              <div className="selectLanguage float-end pb-3">
                <div className="w-100 d-block">
                  <Controller
                    control={control}
                    name="language"
                    render={({ field }) => {
                      return (
                        <>
                          <Select
                            options={selectLanguage}
                            styles={ModifiedSelectStyle}
                            value={selectLanguage.find(
                              (option) => option.value === selectedLanguage
                            )}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                              handleSelectLanguage(selectedOption);
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4 d-flex justify-content-start">
              <div className="mobile-info">
                <div className="info">
                  <span>{t("name")}</span>
                  <span className="mx-2 d-icon-none">:-</span>
                  <span className="span-light">
                    {LastVisitInfo?.firstName} {LastVisitInfo?.lastName}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 d-flex justify-content-center">
              <div className="mobile-info">
                <div className="info">
                  <span>{t("idNumber")}</span>
                  <span className="mx-2 d-icon-none">:-</span>
                  <span className="span-light">{LastVisitInfo?.idNumber}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 d-flex justify-content-end">
              <div className="mobile-info">
                <div className="info">
                  <span>{t("mobile")}</span>
                  <span className="mx-2 d-icon-none">:-</span>
                  <span className="span-light">
                    {LastVisitInfo?.mobileNumber}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 my-3">
              <div>
                <h5>{t("instruction")}</h5>
              </div>
              <div className="instruction-info">
                {allInstruction.map((item, index) => {
                  return (
                    <div key={index + 1}>
                      <p>- {getInstructionText(item)}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {exercise.length > 0 && (
              <div className="col-12 col-md-6 my-3">
                <div>
                  <h5>{t("exercise")}</h5>
                </div>
                <div className="exercise-info ">
                  <div className="d-flex flex-wrap">
                    {allImageStor.map((image, index) => {
                      return (
                        <div key={index + 1} onClick={() => openModal(index)}>
                          {image.image ? (
                            <img
                              className="exercise-image"
                              src={image.image}
                              alt="images"
                            />
                          ) : (
                            <MdImageNotSupported className="image-not-supported" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <DataNotFound />
          </>
        )}
        {/* Modal component */}
        <Modal show={selectedImage !== null} onHide={closeModal} centered>
          <Modal.Body>
            <div className="position-relative">
              <ImageGallery
                items={galleryItems}
                startIndex={selectedImage}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                showNav={false}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default LastVisitInfo;
