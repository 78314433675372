export const API = {
  LOGIN: {
    ID_NUMBER: "idNumber",
    MOBILE_NUMBER: "mobileNumber",
  },
  ONLINE_BOOKING: {
    ID_NUMBER: "idNumber",
    MOBILE_NUMBER: "mobileNumber",
  },
};
