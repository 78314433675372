import { Fragment, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterWrapper from "./Routes";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Fragment>
      {/* ROUTER WRAPPER COMPONENT */}
      <RouterWrapper />

      {/* MODAL COMPONENT */}
      {/* <Suspense>{modal?.delete?.open && <DeleteModal data={modal?.delete?.data} />}</Suspense> */}

      {/* GLOBAL TOASTER COMPONENT */}
      <ToastContainer newestOnTop={true} autoClose={2500} icon={true} />
    </Fragment>
  );
}

export default App;
