import React, { useEffect, useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import Header from "../../../components/HeaderLayout/index";
import {
  useWaitingListQuery,
  useUpdateMutation,
} from "../../../api/onlineAppointment";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ModifiedSelectStyle } from "../../../utils/constants/selectStyle";
import Select from "react-select";
import { toast } from "react-toastify";
import i18n from "../../../i18n";

const WaitingList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  console.log("location", location);
  const appointmentId = location.state?.appointmentId;
  const [waitingListData, setWaitingListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [updateAppointment] = useUpdateMutation();
  const { data: getWaitingList, refetch } = useWaitingListQuery(appointmentId);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "english"
  );

  const form = useForm({});
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    reset,
    setValue,
  } = form;

  useEffect(() => {
    if (getWaitingList?.status === 200) {
      setWaitingListData(getWaitingList?.data);
    } else {
      toast.error(getWaitingList?.message, {
        autoClose: false,
      });
    }
  }, [getWaitingList, refetch]);

  console.log("getWaitingList", getWaitingList);

  const handleUpdateAppointment = async (appointmentId) => {
    const response = await updateAppointment(appointmentId);
    if (response?.data?.status === 200) {
      refetch();
      toast.success(response?.data?.message, { autoClose: 1000 });
      setShowModal(false);
    } else {
      toast.error(response?.error?.data?.message, { autoClose: false });
    }
  };

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const selectLanguage = [
    { value: "english", label: "English" },
    { value: "gujarati", label: "Gujarati" },
    { value: "hindi", label: "Hindi" },
  ];
  const handleSelectLanguage = (selectedOption) => {
    const selectedLanguage = selectedOption?.value;
    setSelectedLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <div>
        <div className="container-fluid p-0">
          <Header />
        </div>
        <div className="selectLanguage float-end p-4">
          <div className="w-100 d-block">
            <Controller
              control={control}
              name="language"
              render={({ field }) => {
                return (
                  <>
                    <Select
                      options={selectLanguage}
                      styles={ModifiedSelectStyle}
                      value={selectLanguage.find(
                        (option) => option.value === selectedLanguage
                      )}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value);
                        handleSelectLanguage(selectedOption);
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>

        <div id="waitingList">
          <div className="waiting-list">
            <div className="h-500">
              <div className="title text-center mb-4">
                <h2 className="font-weight-bold">{t("todayWaitingList")}</h2>
              </div>
              <div className="patient-detail">
                <div className="info-table">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>{t("name")}</td>
                        <td className="text-end">
                          {`${waitingListData.firstName} ${waitingListData.lastName}` ||
                            "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("contactNumber")}</td>
                        <td className="text-end">
                          {waitingListData.mobileNumber || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("societyBuilding")}</td>
                        <td className="text-end">
                          {waitingListData.societyBuilding || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("gender")}</td>
                        <td className="text-end">
                          {waitingListData.gender || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("age")}</td>
                        <td className="text-end">
                          {waitingListData.Age || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("weight")}</td>
                        <td className="text-end">
                          {waitingListData.weight || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="time-div">
                  {waitingListData?.status === "Booked" && (
                    <>
                      <div className="waiting-patient mx-auto">
                        <div className="d-flex align-items-center justify-content-between">
                          <span>{t("waitingPatient")}</span>
                          <span className="text-end">
                            {waitingListData?.waitingPatient}
                          </span>
                        </div>
                      </div>
                      <div className="aprox-time mx-auto">
                        <div className="d-flex align-items-center justify-content-between">
                          <span>{t("yourAproxTime")}</span>
                          <span className="text-end">
                            {waitingListData?.yourAproxTime}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {waitingListData?.status === "Confirm" && (
                    <>
                      <div className="aprox-time mx-auto">
                        <div className="d-flex align-items-center justify-content-between">
                          <span>{t("yourAppointmentTime")}</span>
                          <span className="text-end">
                            {waitingListData?.yourAproxTime}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {waitingListData?.status === "Booked" && (
              <div className="d-flex justify-content-center w-100">
                <button
                  className="btn-save mt-2"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("confirmAppointment")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          className="confirm-modal"
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <Modal.Title className="d-flex align-items-center">
              <AiOutlineExclamationCircle className="me-2" />
              <span>{t("confirmAppointment")}</span>
            </Modal.Title>
            <IoMdClose
              className="float-end icon-close"
              onClick={() => setShowModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p>{t("confirmAppointmentMassage")}</p>
          </Modal.Body>
          <div className="modal-footer">
            <button
              className="btn-save"
              onClick={() => handleUpdateAppointment(appointmentId)}
            >
              {t("ok")}
            </button>
            <button className="btn-cancle" onClick={() => setShowModal(false)}>
              {t("cancel")}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WaitingList;
