import _ from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import { lastVisitApi } from "../api/lastVisit";
import { onlineAppointmentApi } from "../api/onlineAppointment";

const store = configureStore({
  reducer: {
    [lastVisitApi.reducerPath]: lastVisitApi.reducer,
    [onlineAppointmentApi.reducerPath]: onlineAppointmentApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(lastVisitApi.middleware)
      .concat(onlineAppointmentApi.middleware),
});

setupListeners(store.dispatch);

export default store;
