import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLastVisitMutation } from "../../../api/lastVisit";
import { toast } from "react-toastify";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { useTranslation } from "react-i18next";
import "../../styles/app.scss";
import "./style.scss";
import Logo from "../../../assets/images/asthi-logo.svg";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { API } from "../../../utils/constants/variables/apiFields";
import { loginValidationSchema } from "../../../utils/constants/forms/validation";
import { ModifiedSelectStyle } from "../../../utils/constants/selectStyle";
import i18n from "../../../i18n";

const Appointment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [changeLanguage, setChangeLanguage] = useState("english");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setChangeLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);
  const selectLanguage = [
    { value: "english", label: "English" },
    { value: "gujarati", label: "Gujarati" },
    { value: "hindi", label: "Hindi" },
  ];
  const handleSelectLanguage = (selectedOption) => {
    const selectedLanguage = selectedOption?.value;
    setChangeLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };
  const form = useForm({});
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = form;
  return (
    <>
      <div className="container-fluid p-0" id="Appointment">
        <div className="selectLanguage float-end p-4">
          <div className="w-100 d-block">
            <Controller
              control={control}
              name="language"
              render={({ field }) => {
                return (
                  <>
                    <Select
                      options={selectLanguage}
                      styles={ModifiedSelectStyle}
                      value={selectLanguage.find(
                        (option) => option.value === changeLanguage
                      )}
                      placeholder="English"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value);
                        handleSelectLanguage(selectedOption);
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="btn-appointment">
          <button
            className="mx-3"
            onClick={() => {
              navigate(ROUTES.LAST_VISIT);
            }}
          >
            {t("lastVisit")}
          </button>
          <button
            className="mx-3"
            onClick={() => {
              navigate(ROUTES.BOOK_APPOINTMENT);
            }}
          >
            {t("onlineBooking")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Appointment;
