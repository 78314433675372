import { API } from "../variables/apiFields";

export const defaultValues = {
  LOGIN: {
    [API.LOGIN.ID_NUMBER]: "",
    [API.LOGIN.MOBILE_NUMBER]: "",
  },
  ONLINE_BOOKING: {
    [API.ONLINE_BOOKING.ID_NUMBER]: "",
    [API.ONLINE_BOOKING.MOBILE_NUMBER]: "",
  },
};
