import React from "react";
import "./style.scss";
import Logo from "../../assets/images/asthi-logo.svg";

const HeaderLayout = () => {
  return (
    <>
      <div className="header" id="header">
        <div className="logo-img text-center w-100">
          <img src={Logo} alt={"Logo"} />
        </div>
      </div>
    </>
  );
};

export default HeaderLayout;
