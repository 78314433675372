import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLastVisitMutation } from "../../../api/lastVisit";
import { toast } from "react-toastify";
import { ROUTES } from "../../../utils/constants/variables/routes";
import { useTranslation } from "react-i18next";
import "../../styles/app.scss";
import "./style.scss";
import Logo from "../../../assets/images/asthi-logo.svg";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { API } from "../../../utils/constants/variables/apiFields";
import { loginValidationSchema } from "../../../utils/constants/forms/validation";
import { ModifiedSelectStyle } from "../../../utils/constants/selectStyle";
import i18n from "../../../i18n";

const LastVisit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lastVisit] = useLastVisitMutation();
  const [changeLanguage, setChangeLanguage] = useState("english");

  const form = useForm({
    defaultValues: defaultValues.LOGIN,
    resolver: yupResolver(loginValidationSchema()),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = form;

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setChangeLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);
  const selectLanguage = [
    { value: "english", label: "English" },
    { value: "gujarati", label: "Gujarati" },
    { value: "hindi", label: "Hindi" },
  ];
  const handleSelectLanguage = (selectedOption) => {
    const selectedLanguage = selectedOption?.value;
    setChangeLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };
  const onSubmit = async (data) => {
    const response = await lastVisit(data);
    if (response?.data?.status === 200) {
      navigate(ROUTES.LAST_VISIT_INFO, {
        state: { data: response?.data?.data },
      });
    } else {
      toast.error(response?.error?.data?.message, { autoClose: false });
    }
  };
  console.log("errors===", errors);
  return (
    <>
      <div className="container-fluid p-0" id="userLogin">
        <div className="selectLanguage float-end p-4">
          <div className="w-100 d-block">
            <Controller
              control={control}
              name="language"
              render={({ field }) => {
                return (
                  <>
                    <Select
                      options={selectLanguage}
                      styles={ModifiedSelectStyle}
                      value={selectLanguage.find(
                        (option) => option.value === changeLanguage
                      )}
                      placeholder="English"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value);
                        handleSelectLanguage(selectedOption);
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="login">
          <div className="user-login">
            <div className="logo-img mb-5">
              <img src={Logo} alt={"Logo"} />
            </div>

            <div className="login-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="frm-input my-4">
                  <Form.Group>
                    <Form.Control
                      name="idNumber"
                      {...register("idNumber")}
                      type="number"
                      placeholder={t("enterId")}
                    />
                  </Form.Group>
                  <div className="error-msg mt-1">
                    {errors?.idNumber && (
                      <span className="text-danger">
                        {errors?.idNumber?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="frm-input my-4">
                  <Form.Group>
                    <Form.Control
                      name="mobileNumber"
                      {...register("mobileNumber")}
                      type="number"
                      placeholder={t("enterMobile")}
                    />
                  </Form.Group>
                  <div className="error-msg mt-1">
                    {errors?.mobileNumber && (
                      <span className="text-danger">
                        {errors?.mobileNumber?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <button className="btn-save mt-3">{t("logIn")}</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastVisit;
