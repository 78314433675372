import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "LastVisit";
const type = "LastVisit";

export const lastVisitApi = createApi({
  reducerPath: "lastVisitApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/appointment`,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    lastVisit: builder.mutation({
      query: (body) => ({
        url: "/lastVisit",
        method: "POST",
        body,
      }),

      invalidatesTags: [type],
    }),
    getLastVisit: builder.query({
      query: (body) => ({
        url: "/lastVisit",
        method: "POST",
        body,
      }),
      invalidatesTags: [type],
    }),
  }),
});

export const { useLastVisitMutation, useGetLastVisitQuery } = lastVisitApi;
