import * as Yup from "yup";
import i18n from "../../../i18n";
import { API } from "../variables/apiFields";

const loginValidationSchema = () => {
  return Yup.object().shape({
    [API.LOGIN.ID_NUMBER]: Yup.string()
      .trim(i18n.t("REQUIRED"))
      ?.required(i18n.t("REQUIRED")),
    [API.LOGIN.MOBILE_NUMBER]: Yup.string()
      .required(i18n.t("REQUIRED"))
      .min(10, i18n.t("minMaxDigits"))
      .max(10, i18n.t("minMaxDigits")),
  });
};
const onlineBookingValidationSchema = () => {
  return Yup.object().shape({
    [API.ONLINE_BOOKING.MOBILE_NUMBER]: Yup.string()
      .required(i18n.t("REQUIRED"))
      .matches(/^[0-9]+$/, i18n.t("numbersOnly")) // Ensure only numbers are allowed
      .min(10, i18n.t("minMaxDigits"))
      .max(10, i18n.t("minMaxDigits")),
  });

};

export { loginValidationSchema, onlineBookingValidationSchema };


