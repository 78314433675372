import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import * as _ from "lodash";
import Header from "../../../components/HeaderLayout/index";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { defaultValues } from "../../../utils/constants/forms/defaultValues";
import { onlineBookingValidationSchema } from "../../../utils/constants/forms/validation";
import { useGetPatientMutation } from "../../../api/onlineAppointment";
import { toast } from "react-toastify";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ModifiedSelectStyle } from "../../../utils/constants/selectStyle";
import i18n from "../../../i18n";

const BookAppointment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [patientData, setPatientData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [changeLanguage, setChangeLanguage] = useState("english");
  const [createGetPatient] = useGetPatientMutation();
  const isSubmitting = useRef(false);

  useEffect(() => {
    if (patientData?.data?.length > 0) {
      setPatientData(patientData?.data);
    }
  }, [patientData]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setChangeLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const form = useForm({
    defaultValues: defaultValues.ONLINE_BOOKING,
    resolver: yupResolver(onlineBookingValidationSchema()),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    reset,
    setValue,
  } = form;

  useEffect(() => {
    reset(defaultValues.ONLINE_BOOKING);
  }, [changeLanguage, reset]);

  const onSubmit = async (data) => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    const response = await createGetPatient(data);

    if (response?.data?.status === 200) {
      if (response?.data?.data?.length > 0) {
        toast.success(response?.data?.message, { autoClose: 1000 });
        setPatientData(response?.data.data);
        reset(defaultValues.ONLINE_BOOKING);
      } else {
        setShowModal(true);
      }
    } else if (response?.error?.data?.status === "Fail") {
      setShowModal(true);
    }

    isSubmitting.current = false;
  };

  const allPatientData = _.map(patientData, (item) => {
    return {
      patientId: _.get(item, "patientId", ""),
      appointmentId: _.get(item, "appointmentId", ""),
      firstName: _.get(item, "firstName", ""),
      lastName: _.get(item, "lastName", ""),
    };
  });
  const selectLanguage = [
    { value: "english", label: "English" },
    { value: "gujarati", label: "Gujarati" },
    { value: "hindi", label: "Hindi" },
  ];

  const handleSelectLanguage = (selectedOption) => {
    const selectedLanguage = selectedOption?.value;
    setChangeLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };
  console.log("errors=====", errors);
  return (
    <>
      <div>
        <div className="container-fluid p-0">
          <Header />
        </div>
        <div className="selectLanguage float-end p-4">
          <div className="w-100 d-block">
            <Controller
              control={control}
              name="language"
              render={({ field }) => {
                return (
                  <>
                    <Select
                      options={selectLanguage}
                      styles={ModifiedSelectStyle}
                      value={selectLanguage.find(
                        (option) => option.value === changeLanguage
                      )}
                      placeholder="English"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value);
                        handleSelectLanguage(selectedOption);
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>

        <div id="bookAppointment">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="book-appointment">
              <div class="h-500">
                <div className="title text-center mb-5">
                  <h4>{t("bookName")}</h4>
                </div>
                <div className="frm-book-appointment">
                  <div className="input-div">
                    <div className="frm-input my-3">
                      <Form.Group>
                        <Form.Label>{t("mobileNumber")}</Form.Label>
                        <span className="fs-2 text-danger  d-xs-block d-sm-none">
                          *
                        </span>
                        <Form.Control
                          name="mobileNumber"
                          {...register("mobileNumber")}
                          type="number"
                          placeholder={t("enterMobile")}
                        />
                      </Form.Group>
                      <div className="error-msg">
                        {errors?.mobileNumber && (
                          <span className="text-danger">
                            {errors?.mobileNumber?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="frm-input my-3">
                      <Form.Group>
                        <Form.Label>{t("patientId")}</Form.Label>
                        <Form.Control
                          name="idNumber"
                          {...register("idNumber")}
                          type="number"
                          placeholder={t("enterPatient")}
                        />
                      </Form.Group>
                    </div>
                    {patientData?.length > 0 && (
                      <div>
                        <label>{t("patient")}</label>
                        <div className="patient-data">
                          <div>
                            {patientData.map((item, index) => (
                              <div
                                key={item.patientId}
                                onClick={() => {
                                  navigate(
                                    `/book-appointment/waitingList/appointmentId=${item.appointmentId}`,
                                    {
                                      state: item,
                                    }
                                  );
                                }}
                                className="mb-3"
                              >
                                <div className="patient-list">
                                  <span>
                                    {item.firstName} {item.lastName}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {patientData?.length > 0 ? (
                <div className="d-flex justify-content-center d-none">
                  <div>
                    <button className="btn-save mt-2">{t("search")}</button>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="text-center">
                    <button className="btn-save mt-2">{t("search")}</button>
                    <p className="my-2">{t("massagePatientIdNotRequired")}</p>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
      {showModal && (
        <Modal
          className="confirm-modal"
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <Modal.Title className="d-flex align-items-center">
              <AiOutlineExclamationCircle className="me-2" />
              <span>{t("alert")}</span>
            </Modal.Title>
            <IoMdClose
              className="float-end icon-close"
              onClick={() => setShowModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <p>{t("pleaseBookAppointment")} </p>
          </Modal.Body>
          <div className="modal-footer">
            <button
              className="btn-save"
              onClick={() => {
                setShowModal(false);
                reset();
              }}
            >
              {t("ok")}
            </button>
            <button className="btn-cancle" onClick={() => setShowModal(false)}>
              {t("cancel")}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BookAppointment;
