import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../src/locales/en/translation.json";
import translationGUJ from "../src/locales/guj/translation.json";
import translationHN from "../src/locales/hn/translation.json";

// the translations
const resources = {
  english: {
    translation: translationEN,
  },
  gujarati: {
    translation: translationGUJ,
  },
  hindi: {
    translation: translationHN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "gujarati",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

console.log(i18n?.language);

const language = localStorage.getItem("selectedLanguage");

if (!language) {
  localStorage.setItem("selectedLanguage", i18n?.language);
}

export default i18n;
