export const ROUTES = {
  HOME: "/",
  AUTH: {
    LOGIN: "/auth/login",
  },
  LAST_VISIT: "/lastVisit",
  LAST_VISIT_INFO: "/lastVisitInfo",
  BOOK_APPOINTMENT: "/book-appointment",
  WAITING_LIST: "/book-appointment/waitingList/:appointmentId",
};
