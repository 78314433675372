import { createApi } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { baseQueryWithAuthInterceptor } from "./util";
import { showErrorToast } from "../utils/constants/api/toast";

const tagTypes = "onlineAppointment";
const type = "onlineAppointment";

export const onlineAppointmentApi = createApi({
  reducerPath: "onlineAppointmentApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/onlineAppointment`,
  }),
  tagTypes: [type],
  endpoints: (builder) => ({
    getPatient: builder.mutation({
      query: (body) => {
        return {
          url: "/getPatient",
          method: "POST",
          body,
        };
      },

      providesTags: [type],
    }),
    waitingList: builder.query({
      query: (appointmentId) => {
        console.log("appointmentId====", appointmentId);
        return {
          url: `/waitingList/${appointmentId}`,
          method: "GET",
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response?.status === 400 || "Fail") {
          showErrorToast(response?.data?.message);
        }
        return response;
      },

      invalidatesTags: [type],
    }),
    update: builder.mutation({
      query: (appointmentId) => {
        return {
          url: `/update/${appointmentId}`,
          method: "PUT",
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response?.status === 400 || "Fail") {
          showErrorToast(response?.data?.message);
        }
        return response;
      },

      invalidatesTags: [type],
    }),
  }),
});

export const { useGetPatientMutation, useWaitingListQuery, useUpdateMutation } =
  onlineAppointmentApi;
