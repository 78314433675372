import React from "react";
import "./style.scss";

const DataNotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center m-auto page-height">
      <div className="col-12">
        <div className="data-notfound">
          <span className="text-notfound">No data found</span>
        </div>
      </div>
    </div>
  );
};

export default DataNotFound;
